import {
  AcceptOrRejectProposalRequest,
  AddCommentProposolRequest,
  IAmendProposalRequestBody,
  IGetProposalRequestBody,
  INewProposalEntity,
  IProposalCommentEntity,
  IProposalEntity,
  IProposalHistory,
  ISubmitProposalRequestBody,
  ISuggestedProposalByShipmentPayload,
  ISuggestedProposalRequestBody,
  IUpdateAcceptedProposalRequestBody,
  IUpdateProposalRequestBody,
  IUpdateProposalViewRequestBody,
} from 'types/entities/proposal.entity';

import { api } from './base-api';
import { ApiResponse } from './common/response.type';

export const proposalsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPropsals: build.query<IProposalEntity[], void>({
      query: () => ({ url: '/propsal' }),
    }),

    getPropsalById: build.query<IProposalEntity, string>({
      query: (id) => `/proposal/${id}`,
      providesTags: ['ProposalsById'],
      transformResponse: (response: ApiResponse<IProposalEntity>) =>
        response.data,
    }),

    getProposalItemDescription: build.query<string[], void>({
      query: () => '/proposal/description',
      transformResponse: (resp: ApiResponse<string[]>) => resp.data,
    }),

    addPropsal: build.mutation<IProposalEntity, Partial<IProposalEntity>>({
      query: (propsal: IProposalEntity) => ({
        url: '/proposal',
        method: 'POST',
        body: propsal,
        params: { id: propsal.shipmentId },
      }),
    }),
    getProposalsByShipment: build.query<
      IProposalEntity[],
      IGetProposalRequestBody
    >({
      query: ({ shipmentId, ...params }) => ({
        url: `/proposal/shipment/${shipmentId}`,
        params,
      }),

      providesTags: ['ShipmentProposals'],
    }),

    getSuggestedProposalByShipment: build.query<
      ISuggestedProposalByShipmentPayload,
      { shipmentId: string }
    >({
      query: ({ shipmentId }) => ({
        url: `/proposal/suggested-proposal/${shipmentId}`,
      }),

      providesTags: ['SuggestedProposalByShipment'],
    }),

    updatePropsal: build.mutation<IProposalEntity, IUpdateProposalRequestBody>({
      query: (body) => ({
        url: `/proposal/${body.proposal.id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: [
        'ProposalsById',
        'CarriersByProposals',
        'ForwardersByProposals',
        'ProposalHistoryById',
      ],
    }),
    updatePropsalView: build.mutation<
      IProposalEntity,
      IUpdateProposalViewRequestBody
    >({
      query: (body) => ({
        url: `/proposal/comment/view`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['AllShipmentMessages'],
    }),
    updateAcceptedPropsal: build.mutation<
      IProposalEntity,
      IUpdateAcceptedProposalRequestBody
    >({
      query: (body) => ({
        url: `/proposal/additional/${body.params.id}`,
        method: 'PATCH',
        body: body.proposal,
      }),
      invalidatesTags: ['ProposalsById', 'ProposalHistoryById'],
    }),

    addComment: build.mutation<
      IProposalCommentEntity,
      AddCommentProposolRequest
    >({
      query: (propsal: AddCommentProposolRequest) => ({
        url: `/proposal/comment`,
        method: 'POST',
        body: propsal,
      }),
    }),
    uploadFiles: build.mutation<
      string,
      {
        files: FormData;
        params: {
          proposalId: string;
        };
      }
    >({
      query: (body) => {
        const { proposalId = '' } = body.params;

        return {
          url: `/proposal/upload/${proposalId}`,
          method: 'POST',
          body: body.files,
          formData: true,
        };
      },
    }),
    deleteProposalItem: build.mutation<ApiResponse<string>, { id: string }>({
      query(body) {
        return {
          url: `/proposal/item/${body.id}`,
          method: 'DELETE',
        };
      },
    }),

    deleteProposalShipmentItem: build.mutation<
      ApiResponse<string>,
      { id: string }
    >({
      query(body) {
        return {
          url: `/proposal/shipment-item/${body.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['ProposalsById'],
    }),

    acceptProposal: build.mutation<
      IProposalEntity,
      AcceptOrRejectProposalRequest
    >({
      query(payload) {
        return {
          url: `/proposal/accept/${payload.id}`,
          method: 'PATCH',
          params: payload.params,
        };
      },
      invalidatesTags: [
        'ShipmentProposals',
        'ShipmentsById',
        'ProposalsById',
        'Collaborators',
        'ProposalHistoryById',
      ],
    }),

    rejectProposal: build.mutation<
      IProposalEntity,
      AcceptOrRejectProposalRequest
    >({
      query({ id, params }) {
        return {
          url: `/proposal/decline/${id}`,
          method: 'PATCH',
          params,
        };
      },
      invalidatesTags: [
        'ShipmentProposals',
        'ShipmentsById',
        'ProposalsById',
        'Collaborators',
        'ProposalHistoryById',
      ],
    }),

    revokeProposal: build.mutation<IProposalEntity, { id: string }>({
      query({ id }) {
        return {
          url: `/proposal/revoke/${id}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: [
        'ShipmentProposals',
        'ShipmentsById',
        'ProposalsById',
        'Collaborators',
        'ProposalHistoryById',
      ],
    }),

    reawardProposal: build.mutation<IProposalEntity, { id: string }>({
      query({ id }) {
        return {
          url: `/proposal/reaward/${id}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: [
        'ShipmentProposals',
        'ShipmentsById',
        'ProposalsById',
        'Collaborators',
        'ProposalHistoryById',
      ],
    }),

    submitProposal: build.mutation<IProposalEntity, ISubmitProposalRequestBody>(
      {
        query(payload) {
          return {
            url: `/proposal/${payload.shipmentId}/submit`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: ['ShipmentProposals', 'ShipmentsById'],
      }
    ),

    amendAcceptedProposal: build.mutation<
      IProposalEntity,
      IAmendProposalRequestBody
    >({
      query(payload) {
        return {
          url: `/proposal/amend/${payload.proposalId}`,
          method: 'PATCH',
          body: {
            status: payload.status,
          },
        };
      },
      invalidatesTags: [
        'ShipmentProposals',
        'ShipmentsById',
        'ProposalsById',
        'SuggestedProposalByShipment',
      ],
    }),

    createSuggestedProposalById: build.mutation<
      ApiResponse<INewProposalEntity>,
      ISuggestedProposalRequestBody
    >({
      query: (payload) => ({
        url: `/proposal/suggested-proposal/${payload.proposalId}`,
        method: 'POST',
      }),
      invalidatesTags: [
        'ShipmentProposals',
        'ShipmentsById',
        'ProposalsById',
        'SuggestedProposalByShipment',
      ],
    }),

    revokeSuggestedProposalById: build.mutation<
      ApiResponse<INewProposalEntity>,
      ISuggestedProposalRequestBody
    >({
      query: (payload) => ({
        url: `/proposal/suggested-proposal/${payload.proposalId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'ShipmentProposals',
        'ShipmentsById',
        'ProposalsById',
        'SuggestedProposalByShipment',
      ],
    }),
    getHistoryByPrposalId: build.query<ApiResponse<IProposalHistory>, string>({
      query: (proposalId) => `/proposal/history/${proposalId}`,
      providesTags: ['ProposalHistoryById'],
    }),
  }),
});

export const {
  useGetPropsalsQuery,
  useGetPropsalByIdQuery,
  useLazyGetPropsalByIdQuery,
  useAddPropsalMutation,
  useGetProposalsByShipmentQuery,
  useLazyGetProposalsByShipmentQuery,
  useUpdatePropsalMutation,
  useAddCommentMutation,
  useUploadFilesMutation,
  useDeleteProposalItemMutation,
  useAcceptProposalMutation,
  useRejectProposalMutation,
  useRevokeProposalMutation,
  useReawardProposalMutation,
  useDeleteProposalShipmentItemMutation,
  useGetProposalItemDescriptionQuery,
  useUpdateAcceptedPropsalMutation,
  useSubmitProposalMutation,
  useAmendAcceptedProposalMutation,
  useCreateSuggestedProposalByIdMutation,
  useRevokeSuggestedProposalByIdMutation,
  useGetSuggestedProposalByShipmentQuery,
  useUpdatePropsalViewMutation,
  useGetHistoryByPrposalIdQuery,
} = proposalsApi;
